import React from "react";
import md5 from "md5";
import { Level, useNotificationBox } from "./components/NotificationBox";

enum Direction {
  Across,
  Down
}

interface State {
  cells: Cell[][];
  focus?: { row: number; col: number };
  solved?: boolean;
  direction: Direction;
}

interface SelectAction {
  type: "select";
  n: number;
  direction: Direction;
}

interface FocusAction {
  type: "focus";
  row: number;
  col: number;
  direction: Direction;
}

interface SetAction {
  type: "set";
  row: number;
  col: number;
  value: string;
}

type Action = SetAction | FocusAction | SelectAction | { type: "save" };

const load = (): State => {
  if (localStorage.getItem("crossword")) {
    const saved = JSON.parse(localStorage.getItem("crossword") ?? "");
    return saved as State;
  }

  const cells: Cell[][] = [];

  let num = 1;
  const w = { black: false };
  const n = () => ({ number: num++, black: false });
  const b = { black: true };
  const e = { invisible: true };
  const s = () => ({ special: true, number: num++, black: false });

  const grid = [
    [n(), n(), n(), n(), n(), n(), w, b, b, b],
    [n(), w, w, w, w, w, b, n(), n(), n()],
    [n(), w, w, w, w, b, n(), w, w, w],
    [n(), w, w, w, b, b, n(), w, w, w],
    [n(), w, w, w, n(), n(), w, b, b, b],
    [b, b, b, n(), w, w, w, n(), n(), n()],
    [n(), n(), n(), w, b, b, n(), w, w, w],
    [n(), w, w, w, b, n(), w, w, w, w],
    [n(), w, w, b, n(), w, w, w, w, w],
    [b, b, b, s(), s(), s(), s(), s(), s(), { ...w, special: true }]
  ];

  const grid2 = [
    [n(), w, w, w, w, w, b, n(), n(), n()],
    [b, n(), w, w, w, b, n(), w, w, w],
    [n(), b, b, b, b, n(), w, w, w, w],
    [n(), n(), n(), n(), n(), w, w, w, w, w],
    [n(), w, w, w, w, w, w, w, w, w],
    [n(), w, w, w, w, b, b, b, b, w],
    [n(), w, w, w, b, n(), n(), n(), n(), b],
    [n(), w, w, b, n(), w, w, w, w, n()],
    [b, b, b, n(), w, w, w, w, w, w]
  ];

  for (let row = 0; row < 10; row++) {
    cells.push([]);
    for (let col = 0; col < 10; col++) {
      cells[row].push({ row, col, ...grid[row][col] });
    }
    cells[row].push({ col: 10, row, ...e });
    cells[row].push({ col: 11, row, ...e });
    cells[row].push({ col: 12, row, ...e });
  }

  for (let row = 0; row < 9; row++) {
    cells.push([]);
    cells[row + 10].push({ col: 0, row, ...e });
    cells[row + 10].push({ col: 1, row, ...e });
    cells[row + 10].push({ col: 2, row, ...e });
    for (let col = 0; col < 10; col++) {
      cells[row + 10].push({ row: row + 10, col: col + 3, ...grid2[row][col] });
    }
  }

  return { cells, direction: Direction.Across };
};

interface Cell {
  black?: boolean;
  special?: boolean;
  value?: string;
  number?: number;
  row: number;
  col: number;
  highlight?: boolean;
  invisible?: boolean;
}

interface CellInputProps extends Cell {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const embolden = (row: number, col: number) => {
  if (new Date().getDate() <= 29 && !localStorage.getItem("highlight")) {
    return "";
  }

  const pairs = {
    5: { 5: true, 9: true },
    4: { 4: true },
    1: { 9: true },
    2: { 7: true },
    13: { 10: true }
  };
  if (pairs[row]?.[col]) {
    return "text-green-600";
  }
  return "";
};

const CellInput = ({
  black,
  number,
  row,
  col,
  value,
  state,
  invisible,
  highlight,
  special,
  dispatch
}: CellInputProps) => {
  const { focus, direction } = state;
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (focus?.row === row && focus?.col === col) {
      inputRef.current?.focus();
    }
  }, [state.focus, row, col]);

  if (invisible) {
    return (
      <td
        className={`text-center text-2xl lg:text-4xl w-10 lg:w-20 h-10 lg:h-20 print:h-10 print:w-10 relative`}
      ></td>
    );
  }

  return (
    <td
      className={`text-center text-2xl lg:text-4xl border w-10 lg:w-20 h-10 lg:h-20 print:h-10 print:w-10 relative ${
        special ? "border-y-4 border-black" : "border-slate-500"
      }`}
    >
      {number && <div className="text-xs absolute top-1 left-1">{number}</div>}
      {black && <div className="w-full h-full bg-black" />}
      {!black && (
        <input
          ref={inputRef}
          className={`w-full h-full border-none text-4xl text-center text-blue-800 ${
            highlight ? "bg-blue-100" : ""
          } ${embolden(row, col)}`}
          type="text"
          disabled={state.solved}
          value={value ?? ""}
          maxLength={1}
          onClick={e => {
            dispatch({ type: "focus", direction, row, col });
          }}
          onChange={e => {
            console.log("onchange");
            dispatch({
              type: "set",
              row,
              col,
              value: e.target.value.toUpperCase()
            });
          }}
        />
      )}
    </td>
  );
};

interface CrosswordProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const Crossword = ({ state, dispatch }: CrosswordProps) => {
  return (
    <table className="mt-10 mx-auto w-50 print:w-full table-fixed ">
      <tbody>
        {state.cells.map((row, i) => {
          return (
            <tr key={i}>
              {row.map((cell, j) => (
                <CellInput
                  state={state}
                  dispatch={dispatch}
                  key={j}
                  {...cell}
                />
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

interface CluesProps {
  clues: { [key: number]: string };
  direction: Direction;
  dispatch: React.Dispatch<Action>;
}

const Clues = ({ clues, direction, dispatch }: CluesProps) => {
  const sorted = Object.keys(clues).sort(
    (a, b) => parseInt(a, 10) - parseInt(b, 10)
  );
  return (
    <div className="flex flex-col">
      <h1>{direction === Direction.Across ? "Across" : "Down"}</h1>
      <ol>
        {sorted.map(n => (
          <li
            key={n}
            className="cursor-pointer"
            onClick={() =>
              dispatch({
                type: "select",
                n: n as unknown as number,
                direction
              })
            }
          >
            {n}. {clues[parseInt(n, 10)]}
          </li>
        ))}
      </ol>
    </div>
  );
};

const highlight = (state: State): State => {
  if (!state.focus) {
    return state;
  }

  const ns = {
    ...state,
    cells: state.cells.map(row =>
      row.map(cell => ({ ...cell, highlight: false }))
    )
  };

  const { row, col } = state.focus;

  if (state.direction === Direction.Across) {
    for (
      let i = col;
      i < ns.cells[row].length && !ns.cells[row][i].black;
      i++
    ) {
      ns.cells[row][i].highlight = true;
    }

    for (let i = col; i >= 0 && !ns.cells[row][i].black; i--) {
      ns.cells[row][i].highlight = true;
    }
  } else {
    const checkSpecial = !ns.cells[row][col].special;

    for (let i = row; i < ns.cells.length && !ns.cells[i][col].black; i++) {
      ns.cells[i][col].highlight = true;
      if (checkSpecial && ns.cells[i][col].special) {
        break;
      }
    }

    for (let i = row; i >= 0 && !ns.cells[i][col].black; i--) {
      ns.cells[i][col].highlight = true;
      if (ns.cells[i][col].special) {
        break;
      }
    }
  }

  return ns;
};

const acrossClues = {
  1: "happy glasses effect",
  7: "prep gloves for winter",
  11: "help with hands",
  13: "río de España",
  15: "neck accessories",
  8: "one per foot",
  12: "small and white",
  14: "fido food",
  18: "sports purgatory",
  22: "cold in a good way",
  26: "Christmas tree",
  28: "needs a breaker",
  25: "backwards choice",
  27: "fast feet",
  29: "pantry",
  30: "toasty 0 degrees",
  36: "German city",
  40: "respectful, in olde times",
  37: "sound of disapproval",
  41: "an aglet's BFF",
  43: "drop in for a snack",
  44: "no hard shots",
  49: "Christmas tree",
  50: "bi-, eg",
  51: "bloody clear prefix",
  56: "shortened tree",
  52: "small and white",
  57: "change it up",
  59: "when 8 across won't work"
};

const downClues = {
  1: "presents and kids",
  2: "date of Joseph's journal entry processing a surprise",
  3: "bright and jazzy",
  4: "preferred candidate role",
  5: "Obamacare",
  6: "crying",
  8: "live from New York",
  9: "hill",
  10: "Nigerian city",
  12: "C as in confusing",
  19: "tip toe dancing, misspelled",
  20: "modern life with a jackhammer",
  21: "Mr. Triple Axel",
  22: "computer chat",
  23: "life insurance company, abbr",
  24: "square",
  30: "font measure",
  31: "Iranian city",
  32: "girly one",
  33: "Jordan",
  34: "old cinema channel",
  35: "sound of realization",
  37: "cold trees",
  38: "tensile strength affixer",
  39: "Chang to my ears",
  41: "let's do it",
  42: "I've seen this already",
  43: "daughter, abbr",
  45: "600 free hour CD user",
  46: "nice to walk along",
  47: "anti-GOAT vehicle",
  48: "nowadays they're all psuedo",
  52: "fixes items on disk, abbr",
  53: "how Hot Pie would write Arya",
  54: "not elsewhere classified, abbr",
  55: "broadband precursor",
  57: "ChatGPT",
  58: "slash him"
};

const save = (state: State) => {
  localStorage.setItem(
    "crossword",
    JSON.stringify({
      ...state,
      cells: state.cells.map(row =>
        row.map(cell => ({ ...cell, highlight: false }))
      ),
      focus: undefined
    })
  );
};

export default () => {
  const notification = useNotificationBox();

  const [state, dispatch] = React.useReducer((state: State, action: Action) => {
    const ns = { ...state };
    switch (action.type) {
      case "set": {
        const { row, col, value } = action;
        ns.cells[row][col].value = value;
        ns.focus = { row, col };
        if (value) {
          if (state.direction === Direction.Across) {
            ns.focus.col = ns.focus.col + 1;
          } else {
            ns.focus.row = ns.focus.row + 1;
          }
        }
        const letters = ns.cells
          .map(c => c.map(cell => cell.value))
          .flat(5)
          .filter(c => c)
          .join("");

        if (md5(letters) === "bec4402679f760270f9e3ba2c8243d83") {
          ns.solved = true;
          save(ns);
          notification.notify(Level.Success, "Win!", "You solved the puzzle!");
        }
        return { ...ns };
      }
      case "select": {
        const { n, direction } = action;

        for (let row = 0; row < ns.cells.length; row++) {
          for (let col = 0; col < ns.cells[row].length; col++) {
            if (ns.cells[row][col].number === parseInt(n as any, 10)) {
              ns.focus = { row, col };
              ns.direction = direction;
              return highlight({ ...ns });
            }
          }
        }
        break;
      }

      case "focus": {
        const { row, col, direction } = action;
        if (ns.focus?.row === row && ns.focus?.col === col) {
          if (direction === Direction.Across) {
            ns.direction = Direction.Down;
          } else {
            ns.direction = Direction.Across;
          }
        }
        ns.focus = { row, col };
        return highlight({
          ...ns
        });
      }

      case "save": {
        save(state);
        notification.notify(Level.Success, "Saved", "Your work has been saved");
        return state;
      }
      default:
        throw new Error("unknown action");
    }

    return state;
  }, load());

  return (
    <>
      <div className="border-b border-gray-200 bg-white text-center py-4 px-4 flex justify-between print:hidden">
        It turns out making a dense-style crossword is a lot harder than it
        looks. I'm not sure how to make this render nicely on a phone,
        unfortunately. If you want to print it out, be sure to open advanced
        settings and enable background colors, otherwise the dark cells will be
        white and that will be confusing. I misspelled the word for 20 down. The
        Nickelodeon show was Rocko's Modern Life, with a K, and I thought it was
        two Cs. So the word there is ROCCO.{" "}
      </div>
      <div className="flex flex-row print:flex-col">
        <div style={{ pageBreakAfter: "always" }}>
          <Crossword state={state} dispatch={dispatch} />
        </div>
        <div className="flex flex-row justify-between ml-2 mt-8">
          <div className="text-sm">
            <Clues
              dispatch={dispatch}
              clues={acrossClues}
              direction={Direction.Across}
            />
          </div>
          <div className="text-sm ml-4">
            <Clues
              dispatch={dispatch}
              clues={downClues}
              direction={Direction.Down}
            />
          </div>
        </div>
      </div>
      <div>
        {!state.solved && (
          <div className="mt-10 mx-auto w-full flex justify-center print:hidden">
            <button
              type="button"
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => dispatch({ type: "save" })}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </>
  );
};
