import { createRoot } from "react-dom/client";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import Sudoku from "./sudoku";
import Haiku from "./haiku";
import App from "./App";
import Calendar from "./calendar";
import Scramble from "./scramble";
import Crossword from "./crossword";
import Code from "./code";

const Highlight = () => {
  localStorage.setItem("highlight", "true");
  return <div>hi</div>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Calendar />} />
      <Route path="/highlight" element={<Highlight />} />
      <Route path="/sudoku" element={<Sudoku />} />
      <Route path="/haiku" element={<Haiku />} />
      <Route path="/scramble" element={<Scramble />} />
      <Route path="/crossword" element={<Crossword />} />
      <Route path="/code" element={<Code />} />
    </Route>
  )
);

createRoot(document.getElementById("root") as any).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
