import React from "react";
import md5 from "md5";
import { Level, useNotificationBox } from "./components/NotificationBox";

interface Line {
  solved?: boolean;
  values: string[];
  num: number;
  hash: string;
}

const lines: Line[] = [
  {
    num: 9314206,
    hash: "3e194b5730ed8b868224b80ac8d7eed9",
    values: ["", "", "", "", ""]
  },
  {
    num: 103674527,
    hash: "40203abe6e81ed98cbc97cdd6ec4f144",
    values: ["", "", "", "", ""]
  },
  {
    num: 3483397877030,
    hash: "3d4fe7a00bc6fb52a91685d038733d6f",
    values: ["", "", "", "", "", "", "", "", ""]
  },
  {
    num: 177091065,
    hash: "0282fd85176c2a55bcb43b9d32d48857",
    values: ["", "", "", "", "", "", ""]
  },
  {
    num: 3015394239789151,
    hash: "ebc7824e9b9304c9e17677f50b93156c",
    values: ["", "", "", "", "", "", "", "", "", ""]
  },
  {
    num: 818204,
    hash: "98809c3f491160651ce442f657c62218",
    values: ["", "", "", "", ""]
  }
];

interface State {
  lines: Line[];
  solved?: boolean;
}

interface CodeCellProps {
  index: number;
  line: number;
  state: State;
  solved?: boolean;
  value?: string;
  dispatch: React.Dispatch<Action>;
}

const CodeCell = ({ value, line, solved, index, dispatch }: CodeCellProps) => {
  let bg = "";

  if (solved) {
    bg = "bg-green-200";
  }
  return (
    <td
      className={`text-center text-lg lg:text-4xl border border-slate-500 w-10 lg:w-20 h-10 lg:h-20 print:h-20 print:w-20 ${bg}`}
    >
      {
        <input
          className={`w-full h-full border-none text-lg lg:text-4xl text-center text-blue-800 ${bg}`}
          type="text"
          value={value ?? ""}
          maxLength={1}
          onChange={e =>
            dispatch({
              type: "set",
              line,
              index,
              value: e.target.value
            })
          }
        />
      }
    </td>
  );
};

interface SetAction {
  type: "set";
  line: number;
  index: number;
  value: string;
}

type Action = SetAction | { type: "save" };

const load = (): State => {
  if (localStorage.getItem("code")) {
    const saved = JSON.parse(localStorage.getItem("code") ?? "");
    return saved;
  }
  return { lines };
};

export default () => {
  const notification = useNotificationBox();

  const [state, dispatch] = React.useReducer((state: State, action: Action) => {
    switch (action.type) {
      case "set": {
        state.lines[action.line].values[action.index] =
          action.value.toUpperCase();
        const text = state.lines[action.line].values.join("").toLowerCase();
        const hash = md5(text);
        if (hash === state.lines[action.line].hash) {
          state.lines[action.line].solved = true;
          if (state.lines.every(s => s.solved)) {
            notification.notify(
              Level.Success,
              "Yes!",
              "You've solved the code!"
            );
            state.solved = true;
          } else {
            notification.notify(Level.Success, "Yes!", "That line is solved!");
          }
          localStorage.setItem("code", JSON.stringify(state));
        } else {
          state.lines[action.line].solved = false;
        }

        return { ...state };
      }

      case "save":
        localStorage.setItem("code", JSON.stringify(state));
        notification.notify(Level.Success, "Saved", "Your work has been saved");
        return { ...state };
      default:
        throw new Error("unknown action");
    }
  }, load());

  return (
    <>
      {state.lines.map((line, lineNum) => {
        return (
          <table
            key={line.hash}
            className="mt-10 w-50 print:w-full table-fixed border border-slate-500 border-2"
          >
            <tbody>
              <tr>
                <td
                  className="text-4xl text-center text-gray-400 py-2"
                  colSpan={line.values.length}
                >
                  {line.num}
                </td>
              </tr>
              <tr>
                {line.values.map((cell, i) => (
                  <CodeCell
                    key={i}
                    index={i}
                    line={lineNum}
                    state={state}
                    solved={line.solved}
                    value={line.values[i]}
                    dispatch={dispatch}
                  />
                ))}
              </tr>
            </tbody>
          </table>
        );
      })}
      {!state.solved && (
        <div className="mt-10 mx-auto w-full flex justify-center print:hidden">
          <button
            type="button"
            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => dispatch({ type: "save" })}
          >
            Save
          </button>
        </div>
      )}
    </>
  );
};
