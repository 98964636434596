import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import NotificationBox from "./components/NotificationBox";

export default function App() {
  const match = useLocation();

  let title = "Christmas Puzzle Time";

  switch (match.pathname) {
    case "/sudoku":
      title = "SUDOKU";
      break;
    case "/haiku":
      title = "HAIKU";
      break;
    case "/scramble":
      title = "WORD SCRAMBLE";
      break;
    case "/crossword":
      title = "CROSSWORD";
      break;
    case "/code":
      title = "CODE";
      break;
    default:
  }

  return (
    <>
      <NotificationBox>
        <div>
          <div className="border-b border-gray-200 bg-white text-center py-4 px-4 flex justify-between print:hidden">
            <div>
              <Link to="/">📅</Link>
            </div>

            <div>{title}</div>

            <div>&nbsp;</div>
          </div>
          <main>
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
              <Outlet />
            </div>
          </main>
        </div>
      </NotificationBox>
    </>
  );
}
