import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

const Cell = ({
  date,
  children
}: {
  date?: number;
  children?: React.ReactElement | React.ReactElement[] | string;
}) => {
  if (date) {
    return (
      <td className="border border-slate-500 w-40 h-20 lg:h-40 relative">
        <div className="absolute top-0 right-1 text-gray-500">{date}</div>
        <div className="text-center">{children}</div>
      </td>
    );
  }
  return <td className="border border-slate-500 w-40 h-20 lg:h-40"></td>;
};

const lookupSolved = (puzzle: string): boolean => {
  const s = localStorage.getItem(puzzle);
  if (s) {
    const sudoku = JSON.parse(s);
    if (sudoku.solved) {
      return true;
    }
  }
  return false;
};

const Post = ({
  title,
  date,
  collapsed,
  children
}: {
  title: string;
  date: string;
  collapsed?: boolean;
  children: React.ReactElement | React.ReactElement[] | string;
}) => {
  const [collapse, setCollapse] = useState(collapsed);

  if (collapse) {
    return (
      <div className="mx-auto w-3/4 print:hidden mt-4">
        <h1
          className="text-xl flex justify-between items-baseline cursor-pointer pr-4"
          onClick={() => setCollapse(false)}
        >
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>

            {title}
          </div>
          <div className="text-sm text-gray-500">{date}</div>
        </h1>
      </div>
    );
  }

  return (
    <div className="mx-auto w-3/4 print:hidden mt-4">
      <h1
        className="text-xl flex justify-between items-baseline cursor-pointer pr-4"
        onClick={() => setCollapse(true)}
      >
        <div className="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>

          {title}
        </div>
        <div className="text-sm text-gray-500">{date}</div>
      </h1>
      {children}
    </div>
  );
};

const showCode = new Date().getDate() >= 21;

export default () => {
  const sudokuSolved = useMemo(() => lookupSolved("sudoku"), []);
  const haikuSolved = useMemo(() => lookupSolved("haiku"), []);
  const scrambleSolved = useMemo(() => lookupSolved("scramble"), []);
  const crosswordSolved = useMemo(() => lookupSolved("crossword"), []);
  const codeSolved = useMemo(() => lookupSolved("code"), []);

  return (
    <>
      {showCode && (
        <Post date="21Dec23" title="Last Online Puzzle" collapsed={false}>
          <p>
            This is the last online puzzle! I've kept it fairly cryptic so it
            will maybe last more than an hour or two. It'll be tricky if you
            haven't done the other puzzles.{" "}
          </p>
          <p>
            It should be fun to watch the kids tie it all together around
            Christmas!
          </p>
        </Post>
      )}
      <Post date="14Dec23" title="Crossword!" collapsed={showCode}>
        <p>Two more clues after this one, y'all!</p>
      </Post>
      <Post date="12Dec23" title="Word Scramble!" collapsed={true}>
        <p>
          We're again speeding up the cadence a bit to leave room for a
          particularly fun one at the end. Enjoy my take on a word scramble :)
        </p>
      </Post>
      <Post date="10Dec23" title="Haiku Update!" collapsed={true}>
        <p>
          The next puzzle is up! It should go quicker than the sudoku. I've
          moved the schedule up a bit after realizing I'm going to have to
          publish some of the puzzles without my normal computer setup.
        </p>
        <p className="mt-2">
          A few smart people figured out that they could use ChatGPT as a buddy
          to solve the sudoku. Good for you! As such, some of the ensuing
          puzzles will be a bit harder. Definitely use your AI buddy :)
        </p>
        <p className="mt-2">
          If you've solved one, both, or none of the puzzles, let us know! You
          can email/text/message/etc. I'm being lazy here so I'm not tracking
          anything.
        </p>
      </Post>
      <Post date="6Dec23" title="Christmas Scavenger Hunt" collapsed={true}>
        <p>
          After moving to New Zealand, we started running our kids through
          scavenger hunts on Christmas morning, typically with a big present at
          the end. Since we'll be in Texas for the holidays this year, we
          figured we'd spread the <del>torture</del> celebration. If you're a
          Hinkle, Womack, Magnuson, or Heath, or somewhere in between, have a
          go!
        </p>
        <p className="mt-2">
          Note that these puzzles are meant to be fairly challenging, and at
          times might require reaching out to people you know. A new puzzle will
          come out every few days. They build on each other.
        </p>
        <p className="mt-2">
          We'll help you tie it all together when we see you in a few weeks!
        </p>
        <p className="mt-2 text-right">Terry &amp; Sara</p>
      </Post>
      <table className="mt-10 pb-10 mb-10 mx-auto w-3/4 table-fixed border border-slate-500 border-2 border-collapse text-gray-700">
        <thead>
          <tr>
            <td className="text-right pr-1 text-gray-500">Sun</td>
            <td className="text-right pr-1 text-gray-500">Mon</td>
            <td className="text-right pr-1 text-gray-500">Tue</td>
            <td className="text-right pr-1 text-gray-500">Wed</td>
            <td className="text-right pr-1 text-gray-500">Thu</td>
            <td className="text-right pr-1 text-gray-500">Fri</td>
            <td className="text-right pr-1 text-gray-500">Sat</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Cell />
            <Cell />
            <Cell />
            <Cell />
            <Cell />
            <Cell date={1} />
            <Cell date={2} />
          </tr>
          <tr>
            <Cell date={3} />
            <Cell date={4} />
            <Cell date={5} />
            <Cell date={6}>
              <span className="text-lg md:text-4xl">
                <Link to="/sudoku">{sudokuSolved ? "✅" : "🧩"}</Link>
              </span>
            </Cell>
            <Cell date={7} />
            <Cell date={8} />
            <Cell date={9} />
          </tr>
          <tr>
            <Cell date={10}>
              <span className="text-lg md:text-4xl">
                <Link to="/haiku">{haikuSolved ? "✅" : "🧩"}</Link>
              </span>
            </Cell>
            <Cell date={11} />
            <Cell date={12} />
            <Cell date={13}>
              <span className="text-lg md:text-4xl">
                <Link to="/scramble">{scrambleSolved ? "✅" : "🧩"}</Link>
              </span>
            </Cell>
            <Cell date={14} />
            <Cell date={15}>
              <span className="text-lg md:text-4xl">
                <Link to="/crossword">{crosswordSolved ? "✅" : "🧩"}</Link>
              </span>
            </Cell>
            <Cell date={16}>🛬 in 🇺🇸</Cell>
          </tr>
          <tr>
            <Cell date={17} />
            <Cell date={18}></Cell>
            <Cell date={19} />
            <Cell date={20} />
            <Cell date={21}>
              <span className="text-lg md:text-4xl">
                {showCode && <Link to="/code">{codeSolved ? "✅" : "🧩"}</Link>}
                {!showCode && "🔒"}
              </span>
            </Cell>
            <Cell date={22} />
            <Cell date={23} />
          </tr>
          <tr>
            <Cell date={24}>
              🚗 <span className="hidden md:inline">to Lubbock</span>
            </Cell>
            <Cell date={25}>
              <span className="sm:text-4xl md:text-8xl">🎄</span>
            </Cell>
            <Cell date={26} />
            <Cell date={27} />
            <Cell date={28} />
            <Cell date={29} />
            <Cell date={30}>
              🚗 <span className="hidden md:inline">to Austin</span>
            </Cell>
          </tr>
        </tbody>
      </table>
    </>
  );
};
