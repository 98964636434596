import React, { useContext, useEffect, useReducer } from "react";
import { useActionData, useNavigate } from "react-router-dom";

export enum Level {
  Success,
  Error
}

enum ActionType {
  Notify,
  Dismiss
}

interface Notification {
  level: Level;
  title: string;
  details: string;
}

interface Notify {
  type: ActionType.Notify;
  notification: Notification;
  persistent?: boolean;
}

interface Dismiss {
  type: ActionType.Dismiss;
}

type Action = Notify | Dismiss;

interface State {
  notification?: Notification;
  persistent?: boolean;
}

interface ContextProps {
  state: State;
  notify: (
    level: Level,
    title: string,
    details: string,
    persistent?: boolean
  ) => void;
}

const Context = React.createContext<ContextProps>({
  state: {},
  notify: () => {}
});

export const useNotificationBox = () => {
  const ctx = useContext(Context);
  return ctx;
};

const ExclamationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 text-red-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
    />
  </svg>
);

const CheckIcon = () => {
  return (
    <svg
      className="h-6 w-6 text-green-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

interface ActionData {
  notification?: {
    success?: string;
    error?: string;
  };
  redirect?: string;
}

export default ({ children }) => {
  const data = useActionData() as ActionData;
  const navigate = useNavigate();

  const [state, dispatch] = useReducer((_state: State, action: Action) => {
    switch (action.type) {
      case ActionType.Dismiss:
        return {};
      case ActionType.Notify:
        return {
          notification: action.notification,
          persistent: !!action.persistent
        };
      default:
        return {};
    }
  }, {});

  const notify = (
    level: Level,
    title: string,
    details: string,
    persistent?: boolean
  ) =>
    dispatch({
      type: ActionType.Notify,
      notification: { level, title, details },
      persistent
    });

  useEffect(() => {
    if (state.notification && !state.persistent) {
      const timeout = setTimeout(
        () => dispatch({ type: ActionType.Dismiss }),
        10000
      );
      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [state.notification]);

  useEffect(() => {
    if (data?.redirect) {
      navigate(data.redirect);
    }
    if (data?.notification?.success) {
      notify(Level.Success, "Success", data.notification.success);
    }
    if (data?.notification?.error) {
      notify(Level.Error, "Error", data.notification.error);
    }
  }, [data]);

  return (
    <Context.Provider value={{ state, notify }}>
      {children}
      {state.notification && (
        <div className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mt-8">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {state.notification.level === Level.Success && (
                      <CheckIcon />
                    )}
                    {state.notification.level === Level.Error && (
                      <ExclamationIcon />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      {state.notification.title}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {state.notification.details}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      onClick={() => dispatch({ type: ActionType.Dismiss })}
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Context.Provider>
  );
};
