import React from "react";
import md5 from "md5";
import { Level, useNotificationBox } from "./components/NotificationBox";

const LINE1 = "gur svefg gjb, rnfl".split("");
const LINE2 =
  "20;8;5; ;12;1;19;20; ;15;14;5;,; ;20;18;9;3;11;25; ;7;15;9;14;7".split(";");
const LINE3 = "97;47;73; ;67;11;11; ;83;19;2;71; ;23; ;41;11;2;43;?".split(";");

const HASHES = [
  "30e7134e73d91389cfdf619e7069074a",
  "8014ed5bedb2d54d085fe78f8ba9c33b",
  "ff15157faee0b43f59c9e7918191c8f5"
];

class Haiku {
  lines: Cell[][] = [[], [], []];

  linesSolved = [false, false, false];

  solved?: boolean;

  addShades = () => {};

  constructor() {
    if (localStorage.getItem("haiku")) {
      const saved = JSON.parse(localStorage.getItem("haiku") ?? "");
      this.lines = saved.lines;
      this.solved = saved.solved;
      this.linesSolved = saved.linesSolved;
      this.addShades();
      return;
    }

    for (let i = 0; i < LINE1.length; i++) {
      if (LINE1[i] === " " || LINE1[i] === ",") {
        this.lines[0].push({ value: LINE1[i], fixed: true });
      } else {
        this.lines[0].push({});
      }
    }

    for (let i = 0; i < LINE2.length; i++) {
      if (LINE2[i] === " " || LINE2[i] === ",") {
        this.lines[1].push({ value: LINE2[i], fixed: true });
      } else {
        this.lines[1].push({});
      }
    }

    for (let i = 0; i < LINE3.length; i++) {
      if (LINE3[i] === " " || LINE3[i] === "?") {
        this.lines[2].push({ value: LINE3[i], fixed: true });
      } else {
        this.lines[2].push({});
      }
    }
  }
}

interface Cell {
  value?: string | undefined;
  fixed?: boolean;
  shade?: string;
}

type Line = 1 | 2 | 3;

interface HaikuCellProps {
  cell: Cell;
  index: number;
  line: Line;
  state: Haiku;
  dispatch: React.Dispatch<Action>;
}

const highlight =
  localStorage.getItem("highlight") || new Date().getDate() > 29;

const HaikuCell = ({ cell, line, index, state, dispatch }: HaikuCellProps) => {
  let bg = "";

  if (state.linesSolved[line - 1]) {
    bg = "bg-green-200";
  }
  if (highlight) {
    if (line === 3) {
      if (index > 3 && index < 7) {
        bg = "bg-red-100";
      } else if (index < 3) {
        bg = "bg-orange-100";
      }
    } else if (line === 2) {
      if (index > 20) {
        bg = "bg-blue-100";
      }
    }
  }

  return (
    <td
      className={`text-center text-lg lg:text-4xl border border-slate-500 w-10 lg:w-20 h-10 lg:h-20 print:h-20 print:w-20 ${bg}`}
    >
      {cell.fixed && (
        <input
          className="w-full h-full border-none text-lg lg:text-4xl text-center"
          type="text"
          value={cell.value ?? ""}
          disabled
        />
      )}
      {!cell.fixed && (
        <input
          className={`w-full h-full border-none text-lg lg:text-4xl text-center text-blue-800 ${bg}`}
          type="text"
          value={cell.value ?? ""}
          disabled={state.solved}
          maxLength={1}
          onChange={e =>
            dispatch({
              type: "set",
              line,
              index,
              value: e.target.value
            })
          }
        />
      )}
    </td>
  );
};

interface SetAction {
  type: "set";
  line: Line;
  index: number;
  value: string;
}

type Action = SetAction | { type: "save" };

const s = new Haiku();

export default () => {
  const notification = useNotificationBox();

  const [state, dispatch] = React.useReducer((state: Haiku, action: Action) => {
    switch (action.type) {
      case "set": {
        state.lines[action.line - 1][action.index].value = action.value;
        const text = state.lines[action.line - 1]
          .map(c => c.value)
          .join("")
          .toLowerCase();
        const hash = md5(text);
        if (hash === HASHES[action.line - 1]) {
          state.linesSolved[action.line - 1] = true;
          if (state.linesSolved.every(s => s)) {
            notification.notify(
              Level.Success,
              "Yes!",
              "You've solved the haiku!"
            );
            state.solved = true;
          } else {
            notification.notify(Level.Success, "Yes!", "That line is solved!");
          }
          localStorage.setItem("haiku", JSON.stringify(state));
        } else {
          state.linesSolved[action.line - 1] = false;
        }

        return { ...state };
      }

      case "save":
        localStorage.setItem("haiku", JSON.stringify(state));
        notification.notify(Level.Success, "Saved", "Your work has been saved");
        return { ...state };
      default:
        throw new Error("unknown action");
    }
  }, s);

  return (
    <>
      <div className="mx-auto w-full text-center print:hidden">
        <p>
          Below is a ciphered haiku. Each line uses a different variant of a
          substitution cipher.
        </p>
      </div>
      <table className="mt-10 mx-auto w-50 print:w-full table-fixed border border-slate-500 border-2">
        <tbody>
          <tr>
            {LINE1.map((letter, i) => (
              <td
                key={i}
                className="text-center text-lg lg:text-4xl border border-slate-500 w-10 lg:w-20 h-10 lg:h-20 print:h-20 print:w-20"
              >
                {letter}
              </td>
            ))}
          </tr>
          <tr>
            {state.lines[0].map((cell, i) => (
              <HaikuCell
                key={i}
                cell={cell}
                index={i}
                line={1}
                state={state}
                dispatch={dispatch}
              />
            ))}
          </tr>
        </tbody>
      </table>
      <table className="mt-10 mx-auto w-50 print:w-full table-fixed border border-slate-500 border-2">
        <tbody>
          <tr>
            {LINE2.map((letter, i) => (
              <td
                key={i}
                className="text-center text-lg lg:text-4xl border border-slate-500 w-10 lg:w-20 h-10 lg:h-20 print:h-20 print:w-20"
              >
                {letter}
              </td>
            ))}
          </tr>
          <tr>
            {state.lines[1].map((cell, i) => (
              <HaikuCell
                key={i}
                cell={cell}
                index={i}
                line={2}
                state={state}
                dispatch={dispatch}
              />
            ))}
          </tr>
        </tbody>
      </table>
      <table className="mt-10 mx-auto w-50 print:w-full table-fixed border border-slate-500 border-2">
        <tbody>
          <tr>
            {LINE3.map((letter, i) => (
              <td
                key={i}
                className="text-center text-lg lg:text-4xl border border-slate-500 w-10 lg:w-20 h-10 lg:h-20 print:h-20 print:w-20"
              >
                {letter}
              </td>
            ))}
          </tr>
          <tr>
            {state.lines[2].map((cell, i) => (
              <HaikuCell
                key={i}
                cell={cell}
                index={i}
                line={3}
                state={state}
                dispatch={dispatch}
              />
            ))}
          </tr>
        </tbody>
      </table>
      {!state.solved && (
        <div className="mt-10 mx-auto w-full flex justify-center print:hidden">
          <button
            type="button"
            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => dispatch({ type: "save" })}
          >
            Save
          </button>
        </div>
      )}
    </>
  );
};
