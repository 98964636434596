import React from "react";
import md5 from "md5";
import { Level, useNotificationBox } from "./components/NotificationBox";

function decode(key: string, encoded: string) {
  const b = atob(encoded);
  let r = "";

  for (let i = 0; i < b.length; i++) {
    const c = String.fromCharCode(
      key.toLowerCase().charCodeAt(i % key.length) ^ b.charCodeAt(i)
    );
    if (c !== "\u0000") {
      r += c;
    }
  }

  return r;
}

interface WordProps {
  word: ScrambledWord;
}

const highlight =
  localStorage.getItem("highlight") || new Date().getDate() > 29;

const Word = ({ word }: WordProps) => {
  return (
    <div className="mb-2">
      <div className="flex flex-row">
        {Array.from({ length: word.length }).map((_, i) => (
          <React.Fragment key={i}>
            {word.specials?.[i] && (
              <div className="w-10 h-10 text-center">
                <input
                  className={`w-full h-full border-none text-lg lg:text-4xl text-center text-blue-800`}
                  value={word.specials[i]}
                  disabled
                  type="text"
                />
              </div>
            )}
            <div className="w-10 h-10 border border-slate-500 border-1 text-center">
              <input
                className={`w-full h-full border-none text-lg lg:text-4xl text-center text-blue-800 ${
                  word.solved ? "bg-green-200" : ""
                } ${
                  highlight &&
                  word.solved &&
                  word.hash === "91f41dc0bbbd2a0288c6bb3f4ea94cf8" &&
                  (i === 3 || i === 4 || i === 5)
                    ? "font-bold"
                    : ""
                }`}
                value={word.solved ? word.letters?.toUpperCase()[i] : ""}
                disabled
                type="text"
              />
            </div>
          </React.Fragment>
        ))}
      </div>
      {word.solved && word.letters && (
        <div className="text-xs text-green-400">
          {decode(word.letters, word.d)}
        </div>
      )}
    </div>
  );
};

interface KeyProps {
  enabled: boolean;
  v: string;
  onClick: (k: string) => void;
}

const Key = ({ enabled, v, onClick }: KeyProps) => {
  return (
    <div
      onClick={() => (enabled ? onClick(v) : null)}
      className={`shadow-lg w-10 h-10 lg:h-15 lg:w-15 xl:h-20 xl:w-20 border border-slate-500 border-2 text-center pt-1 xl:pt-4 align-center text-lg lg:text-2xl xl:text-4xl ${
        enabled ? "cursor-pointer bg-blue-200 hover:bg-blue-400" : "bg-gray-200"
      }`}
    >
      {v}
    </div>
  );
};

interface KeyboardProps {
  onSubmit: (word: string) => void;
  counts: Record<string, number>;
}

const Keyboard = ({ counts, onSubmit }: KeyboardProps) => {
  const [v, setV] = React.useState("");

  const click = (k: string) => {
    setV(v + k);
  };

  const ROW1 = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
  const ROW2 = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
  const ROW3 = ["Z", "X", "C", "V", "B", "N", "M"];

  return (
    <div className="mx-auto py-1 px-1 shadow-lg lg:min-w-1/4 md:w-1/2 border border-1 text-center flex flex-col mt-4 bg-gray-100">
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(v);
          setV("");
        }}
      >
        <div className="flex mx-2 mb-2">
          <input
            className={`w-full h-full border-none text-4xl text-center text-blue-800`}
            type="text"
            value={v}
            onChange={e => setV(e.target.value.toUpperCase())}
          />
          <button
            className="rounded-md bg-blue-600 ml-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
      <div className="flex flex-row justify-between mt-1 px-2">
        {ROW1.map(k => (
          <Key
            key={k}
            enabled={!!(counts[k] && counts[k] > 0)}
            onClick={click}
            v={k}
          />
        ))}
      </div>
      <div className="flex flex-row justify-between mt-1 px-2">
        <div></div>
        {ROW2.map(k => (
          <Key
            key={k}
            enabled={!!(counts[k] && counts[k] > 0)}
            onClick={click}
            v={k}
          />
        ))}
        <div></div>
      </div>
      <div className="flex flex-row justify-between mt-1 px-2">
        <div></div>
        <div></div>
        {ROW3.map(k => (
          <Key
            key={k}
            enabled={!!(counts[k] && counts[k] > 0)}
            onClick={click}
            v={k}
          />
        ))}
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

interface ScrambledWord {
  length: number;
  hash: string;
  letters?: string;
  solved?: boolean;
  d: string;
  specials?: Record<number, string>;
}

const words: ScrambledWord[] = [
  {
    length: 11,
    hash: "611e5abdb2ea1cab1209f6f74cc39d2d",
    d: "FAwCAQgdAgpFIA4fSVxUWV0=",
    specials: {
      "7": " "
    }
  },
  {
    length: 12,
    hash: "91f41dc0bbbd2a0288c6bb3f4ea94cf8",
    d: "AgoABBMWHRRSIRYQFQIOBABFSkBCUQ==",
    specials: {
      "5": " "
    }
  },
  {
    length: 10,
    hash: "756c455b0a8843bac42ebb608bd29ed2",
    d: "HwQIBAYSFgdBOBgPAUFVUUNW",
    specials: {}
  },
  {
    length: 4,
    hash: "e66a124f9cabd3198d84dd68c8c87cf7",
    d: "EQQeFgISFxdDKwcdBkFAQ1NX",
    specials: {}
  },
  {
    length: 9,
    hash: "875841eaad5e03a122f5d9d0dcab4eb6",
    d: "GgQcFRgVAAFULAQTFRQEABdUWlFARg==",
    specials: {
      "5": " "
    }
  },
  {
    length: 7,
    hash: "79b1c4067979f782d9c13f87450d5d1b",
    d: "GgoYAxQJHwxPNRYHExZIXURWQg==",
    specials: {
      "3": " "
    }
  },
  {
    length: 9,
    hash: "9f05aa4202e4ce8d6a72511dc735cce9",
    d: "BxUKDAsbDgACHwlJNxUbCQQcHhEHRFZeTRMLHxUIFwAWTSwPClBbVFVF",
    specials: {}
  },
  {
    length: 11,
    hash: "014eb9bf7668dac6775d7009b39a8604",
    d: "AAQYBBUcEA1MJhAcBFRTRF9C",
    specials: {}
  },

  {
    length: 5,
    hash: "51635089578f68fc9efdb61e6a760b64",
    d: "BQQACQQEBAhMLwIPCUxXR1FU",
    specials: {
      "4": "-"
    }
  },
  {
    length: 4,
    hash: "a6fe881cecd3fb7660083aea35cce430",
    d: "EAoAEQMcCRBCIQMCBwIOERBPXkRSVw==",
    specials: {}
  },
  {
    length: 8,
    hash: "c9bce6ab8aeaf9dde4668feb893262a4",
    d: "BgpUAREDFxgHCh1THQFSSjBPNhAAABAcBk9LQ0RW",
    specials: {
      "3": " "
    }
  },
  {
    length: 11,
    hash: "1ff61e91349d3f6623a81ccd3d881fa1",
    d: "GgQeFxgDChBUKx0eBB8QHAJPRkRUQg==",
    specials: {
      "5": " "
    }
  },
  {
    length: 8,
    hash: "0e671f0921e3731896042e4dd598c60c",
    d: "HgwKAA4VFQ1MJwkTCgsSDB5JVFVeVA==",
    specials: {
      "4": " ",
      "6": " "
    }
  },
  {
    length: 12,
    hash: "c412de7683dc97126e523b683adb540b",
    d: "Bg0JCQQUCglPMAwHBh0EHhxHXV1eQg==",
    specials: {
      "3": " ",
      "7": " "
    }
  },
  {
    length: 13,
    hash: "2ffd12e8ad1cd2b9ba000f9c2bd11d73",
    d: "Bg0JCAAJABZVPQsVBhEFBwgTWldCRFo=",
    specials: {
      "3": " ",
      "7": " "
    }
  }
];

const COUNTS = {
  A: 13,
  B: 1,
  C: 2,
  D: 3,
  E: 16,
  F: 5,
  G: 3,
  H: 5,
  I: 7,
  L: 8,
  M: 5,
  N: 6,
  O: 11,
  P: 7,
  R: 12,
  S: 6,
  T: 11,
  U: 3,
  V: 1,
  W: 1,
  X: 1,
  Y: 4,
  Z: 3
};

interface State {
  words: ScrambledWord[];
  counts: { [k: string]: number };
  solved?: boolean;
}

interface SubmitAction {
  type: "submit";
  word: string;
}

type Action = SubmitAction;

const load = (): State => {
  if (localStorage.getItem("scramble")) {
    const saved = JSON.parse(localStorage.getItem("scramble") ?? "");
    return saved as State;
  }
  return { words, counts: COUNTS };
};

export default () => {
  const notification = useNotificationBox();

  const [state, dispatch] = React.useReducer((state: State, action: Action) => {
    switch (action.type) {
      case "submit": {
        const word = action.word.toLowerCase().replace(/[ -]*/g, "");

        const hash = md5(word);
        let match = false;
        const words = state.words.map(w => {
          if (w.hash === hash) {
            match = true;
            return { ...w, letters: word.toUpperCase(), solved: true };
          }
          return w;
        });

        if (!match) {
          notification.notify(
            Level.Error,
            "Nope",
            "Sorry, that's not one of the words"
          );
        }

        const counts = { ...COUNTS };
        let solved = true;
        for (const w of words) {
          for (const l of w.letters ?? []) {
            counts[l]--;
          }
          solved = solved && !!w.solved;
        }

        if (solved) {
          notification.notify(
            Level.Success,
            "Win!",
            "You found all the words!"
          );
        }
        const ns = { ...state, counts, words, solved };
        localStorage.setItem("scramble", JSON.stringify(ns));
        return ns;
      }
    }
    return state;
  }, load());

  return (
    <>
      <div className="mx-auto w-full text-left print:hidden mb-4">
        <p>
          There are some words that need figuring out. The keyboard below
          highlights available letters. If a letter is gray, it won't help you
          solve anything. Just to be helpful, there are 15 children (well, some
          are a bit grown up now) amongst the intended audience, and 15 things
          to solve. Maybe that's on purpose.
        </p>
        <p>
          While talking to Nate I let it slip that it's 15 <b>movies</b>. So,
          now y'all are on equal footing.
        </p>
      </div>
      <div className="flex flex-col">
        {state.words.map(w => (
          <Word key={w.hash} word={w} />
        ))}
      </div>
      {!state.solved && (
        <Keyboard
          counts={state.counts}
          onSubmit={word => dispatch({ type: "submit", word })}
        />
      )}
    </>
  );
};
