import React from "react";
import md5 from "md5";
import { Level, useNotificationBox } from "./components/NotificationBox";

class Cell {
  candidates: number[] = [];

  value: number | undefined;

  fixed: boolean;

  shade?: string;
}

class Sudoku {
  cells: Cell[][] = [];

  solved?: boolean;

  addShades = () => {
    this.cells[0][1].shade = "bg-red-100";
    this.cells[2][2].shade = "bg-orange-100";
    this.cells[1][8].shade = "bg-yellow-100";
    this.cells[3][4].shade = "bg-green-100";
    this.cells[8][8].shade = "bg-indigo-100";
    this.cells[8][1].shade = "font-bold";
  };

  constructor() {
    if (localStorage.getItem("sudoku")) {
      const saved = JSON.parse(localStorage.getItem("sudoku") ?? "");
      this.cells = saved.cells;
      this.solved = saved.solved;
      this.addShades();
      return;
    }

    for (let i = 0; i < 9; i++) {
      this.cells.push([]);
      for (let j = 0; j < 9; j++) {
        this.cells[i].push(new Cell());
      }
    }

    const grid = [
      [1, 0, 0, 9, 6, 0, 0, 4, 0],
      [0, 4, 3, 0, 0, 0, 0, 2, 0],
      [0, 0, 0, 0, 3, 7, 1, 0, 0],
      [0, 1, 0, 0, 0, 4, 7, 8, 0],
      [0, 0, 4, 0, 0, 0, 0, 0, 0],
      [0, 0, 6, 0, 7, 0, 4, 5, 0],
      [0, 0, 0, 0, 0, 1, 8, 3, 5],
      [0, 9, 0, 7, 4, 0, 0, 0, 0],
      [0, 0, 0, 0, 5, 8, 9, 7, 0]
    ];

    this.addShades();

    for (let i = 0; i < grid.length; i++) {
      const row = grid[i];
      for (let j = 0; j < row.length; j++) {
        const cell = row[j];
        if (cell) {
          this.cells[i][j].value = cell;
          this.cells[i][j].fixed = true;
        }
      }
    }
  }
}

const GridCell = ({
  cell,
  right,
  down,
  row,
  col,
  state,
  dispatch
}: {
  row: number;
  col: number;
  cell: Cell;
  right: boolean;
  down: boolean;
  state: Sudoku;
  dispatch: React.Dispatch<{ type: string; payload: any }>;
}) => {
  return (
    <td
      className={`text-center text-4xl border border-slate-500 w-10 lg:w-20 h-10 lg:h-20 print:h-20 print:w-20 ${
        right ? "border-r-2" : ""
      } ${down ? "border-b-2" : ""} ${state.solved ? cell.shade : ""}`}
    >
      {cell.fixed && (
        <input
          className="w-full h-full border-none text-4xl text-center"
          type="text"
          value={cell.value ?? ""}
          disabled
        />
      )}
      {!cell.fixed && (
        <input
          className={`w-full h-full border-none text-4xl text-center text-blue-800  ${
            state.solved ? cell.shade : ""
          }`}
          type="text"
          value={cell.value ?? ""}
          disabled={state.solved}
          onChange={e =>
            dispatch({
              type: "set",
              payload: { row, col, value: parseInt(e.target.value, 10) }
            })
          }
        />
      )}
    </td>
  );
};

const s = new Sudoku();

export default () => {
  const notification = useNotificationBox();

  const [state, dispatch] = React.useReducer(
    (state: Sudoku, action: { type: string; payload: any }) => {
      switch (action.type) {
        case "set": {
          const { row, col, value } = action.payload;
          if (isNaN(value)) {
            state.cells[row][col].value = undefined;
          } else if (value === undefined || (value > 0 && value < 10)) {
            state.cells[row][col].value = value;
          }
          return { ...state };
        }
        case "save":
          localStorage.setItem("sudoku", JSON.stringify(state));
          notification.notify(
            Level.Success,
            "Saved",
            "Your work has been saved"
          );
          return { ...state };
        case "showHint":
          state.cells[0][1].value = 7;
          state.cells[0][2].value = 8;
          state.cells[1][4].value = 1;
          state.cells[1][5].value = 5;
          state.cells[4][6].value = 3;
          state.cells[4][7].value = 1;
          state.cells[4][8].value = 9;
          return { ...state };

        case "check": {
          const hash = md5(
            JSON.stringify(
              state.cells.map(row => row.map(c => c.value)).flat(5)
            )
          );
          if (hash === "dc591ac9f4b5825a0aae6ed3ad722e81") {
            notification.notify(
              Level.Success,
              "Win!",
              "You solved the puzzle!"
            );
            localStorage.setItem(
              "sudoku",
              JSON.stringify({ ...state, solved: true })
            );
            return { ...state, solved: true };
          }
          notification.notify(
            Level.Error,
            "Nope",
            "Sorry, the puzzle isn't correct"
          );
          return { ...state, solved: false };
        }
        default:
          throw new Error();
      }
    },
    s
  );

  return (
    <>
      <div className="mx-auto w-full text-center print:hidden">
        <p>
          If you're unfamiliar with this kind of puzzle, check out{" "}
          <a href="https://sudoku.com" target="_blank" rel="noreferrer">
            sudoku.com
          </a>
          . When you've entered in all the missing numbers, click the check
          button to see if you've solved the puzzle correctly.
        </p>
      </div>
      <table className="mt-10 mx-auto w-50 print:w-full table-fixed border border-slate-500 border-2">
        <tbody>
          {s.cells.map((row, i) => {
            return (
              <tr key={i}>
                {row.map((col, j) => (
                  <GridCell
                    key={j}
                    row={i}
                    col={j}
                    right={(j + 1) % 3 === 0 && j !== 8}
                    down={(i + 1) % 3 === 0 && i !== 8}
                    cell={col}
                    state={state}
                    dispatch={dispatch}
                  />
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!state.solved && (
        <div className="mt-10 mx-auto w-full flex justify-center print:hidden">
          <button
            type="button"
            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => dispatch({ type: "save", payload: null })}
          >
            Save
          </button>

          <button
            type="button"
            className="rounded-md bg-blue-600 ml-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => dispatch({ type: "check", payload: null })}
          >
            Check
          </button>

          <button
            type="button"
            className="rounded-md bg-blue-600 ml-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => dispatch({ type: "showHint", payload: null })}
          >
            Hint #1
          </button>
        </div>
      )}
    </>
  );
};
